import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/breast-cancer-awareness-24/1.jpg';
import blogd2 from '../../assets/images/2024/breast-cancer-awareness-24/2.jpg';
import blogd3 from '../../assets/images/2024/breast-cancer-awareness-24/3.jpg';
import blogd4 from '../../assets/images/2024/breast-cancer-awareness-24/4.jpg';
import blogd5 from '../../assets/images/2024/breast-cancer-awareness-24/5.jpg';
import blogd6 from '../../assets/images/2024/breast-cancer-awareness-24/6.jpg';
import blogd7 from '../../assets/images/2024/breast-cancer-awareness-24/7.jpg';
import blogd8 from '../../assets/images/2024/breast-cancer-awareness-24/8.jpg';
import blogd9 from '../../assets/images/2024/breast-cancer-awareness-24/9.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Lagos University Teaching Hospital Celebrates Breast Cancer
                  Awareness Day: "No One Should Face Breast Cancer Alone"
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Lagos University Teaching Hospital (LUTH) proudly joined
                  the world to raise awareness for breast cancer during Breast
                  Cancer Awareness Day, celebrated annually in October. This
                  year's theme, "No One Should Face Breast Cancer Alone,"
                  reflects our shared commitment to ensuring that those affected
                  by breast cancer receive the support, care, and understanding
                  they need throughout their journey.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The event was officially opened with a warm welcome address
                  delivered by Dr. Ayodeji Oluwole, Chairman of the Medical
                  Advisory Committee(CMAC), on behalf of the Chief Medical
                  Director(CMD), Prof. Wasiu Adeyemo. Dr. Oluwole highlighted
                  LUTH's ongoing dedication to providing comprehensive cancer
                  care and emphasized the importance of coming together as a
                  community to support those battling breast cancer.
                  <br />
                  As part of the awareness activities, LUTH hosted a special
                  event, Go Pink Day, where healthcare professionals, survivors,
                  and advocates came together to highlight the importance of
                  early detection and the critical role of breast cancer
                  screenings.
                  <br />
                  Dr. Habeebu Muhammed, one of our esteemed oncologists,
                  delivered an informative session on the latest breast cancer
                  screening methods. He discussed the importance of regular
                  self-examinations, mammograms, and clinical breast exams in
                  detecting cancer early, when it is most treatable. Dr. Habeeb
                  emphasized that early detection saves lives and that no one
                  should hesitate to seek medical advice or screenings.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Dr. Lawal Abdulrazzaq, another leading expert, offered a
                  powerful message during the event: "Breast cancer is not a
                  death sentence." He went on to explain, "Death is pronounced
                  at birth. Breast cancer merely tries to execute the sentence.
                  Is it always successful? No. But sometimes, it can be." His
                  words underscored the importance of hope, early intervention,
                  and access to proper treatment, reminding everyone that a
                  breast cancer diagnosis does not mean the end, but rather the
                  beginning of a fight that can be won.
                  <br />
                  Throughout the event, LUTH reaffirmed its commitment to
                  supporting breast cancer patients, survivors, and their
                  families, providing a comprehensive range of services from
                  diagnosis to treatment and emotional support. Together, we can
                  ensure that no woman has to face breast cancer alone.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                 More Pictures
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                {/* <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p> */}
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
